<template>
  <CartGroupLayout
    :shop="order !== null ? order.shop : {}"
    :products="order !== null ? order.ordered_products : []"
  >
    <!-- Header Actions-->
    <template v-slot:header-actions v-if="order != null">
      <span class="text-uppercase color-secondary">{{
        $t("orders.status." + order.status)
      }}</span>
    </template>

    <!--  Product  -->
    <template v-slot:product="{ product }">
      <order-product
        :order_status="order.status"
        :total_price="order.total_amount"
        :ordered_product="product"
      />
    </template>

    <template v-slot:actions v-if="order != null">
      <!--   Track Order   -->
      <!--   Delivery Type: by_driver Shop Delivery Type: System Order Status: shipped   -->
      <track-order
        :order_id="order.id"
        v-if="
          order.status === 'shipped' &&
            order.delivery_type === 'by_driver' &&
            order.shop.delivery_type === 'system' &&
            order.driver !== null
        "
      >
        <button class="btn btn-primary btn-skinny font-size-sm w-100">
          {{ $t("orders.tracking.button_label") }}
        </button>
      </track-order>

      <!--    Process Payment    -->
      <div class="mb-2" v-if="order.status === 'payment_pending'">
        <router-link
          class="btn btn-danger btn-skinny font-size-sm w-100"
          :to="'/orders/' + order.id + '/pay'"
        >
          {{ $t("pay_online.pay_btn") }}
        </router-link>
      </div>

      <!--    Order Received    -->
      <div class="mb-2" v-if="order.status === 'delivered'">
        <confirm-modal
          :title="$t('orders.order_received.modal_title')"
          :body="$t('orders.order_received.modal_body')"
          modalDialogClasses="modal-notice"
          confirmBtnClass="btn-primary"
          declinedBtnClass="btn-default"
          @confirmed="order_received"
        >
          <button
            type="button"
            class="btn btn-primary btn-skinny font-size-sm w-100"
          >
            <loading :loading="loading.order_received">
              {{ $t("orders.order_received.btn_text") }}
            </loading>
          </button>
        </confirm-modal>
      </div>

      <!--     Rate shop     -->
      <div class="mb-2">
        <rate-modal
          :allow_rating="order.status === 'accepted'"
          :presentation_image="
            window.Routes.getImage(order.shop.logo, 'preview')
          "
          :presentation_text="order.shop.name"
          :rating="order.shop.rating"
          :my_rating="order.shop.my_rating"
          @ratingSaved="shop_rated($event, order.shop.id)"
        >
          <template v-slot:default>
            <button
              type="button"
              class="btn btn-outlined btn-skinny font-size-sm w-100"
            >
              <i class="far fa-star me-2"></i> {{ $t("ratings.rate_shop") }}
            </button>
          </template>

          <template v-slot:rating-exists="{ my_rating }">
            <button
              disabled
              class="btn btn-disabled btn-my-rating font-size-sm w-100"
            >
              {{ $t("ratings.your_shop_rate") }}:
              <span class="ms-2"
                ><i class="fas fa-star"></i> {{ my_rating.rating }}</span
              >
            </button>
          </template>
        </rate-modal>
      </div>

      <!--     Rate Driver     -->
      <div class="mb-2" v-if="order.driver !== null">
        <rate-modal
          :allow_rating="order.status === 'accepted'"
          :presentation_image="null"
          :presentation_text="order.driver.name"
          :rating="order.driver.rating"
          :my_rating="order.driver.my_rating"
          @ratingSaved="driver_rated($event, order.driver.id)"
        >
          <template v-slot:default>
            <button
              type="button"
              class="btn btn-outlined btn-skinny font-size-sm w-100"
            >
              <i class="far fa-star me-2"></i> {{ $t("ratings.rate_driver") }}
            </button>
          </template>

          <template v-slot:rating-exists="{ my_rating }">
            <button
              disabled
              class="btn btn-disabled btn-my-rating font-size-sm w-100"
            >
              {{ $t("ratings.your_driver_rate") }}:
              <span class="ms-2"
                ><i class="fas fa-star"></i> {{ my_rating.rating }}</span
              >
            </button>
          </template>
        </rate-modal>
      </div>

      <!--    Open Dispute     -->

      <div class="mb-2" v-if="order.status === 'accepted'">
        <dispute-modal :order="order"> </dispute-modal>
      </div>
    </template>

    <template v-slot:footer v-if="order != null">
      <CartTotalBar
        :data_ready="true"
        :currency="order.currency"
        :delivery_price="order.shipping_price"
        :total_price="order.total_amount"
        :total_weight="order.total_weight"
      />
    </template>
  </CartGroupLayout>
</template>

<script>
import { mapActions } from "vuex";
import CartGroupLayout from "@/views/Cart/partials/CartGroupLayout";
import { Promise } from "core-js";
import RateModal from "@/components/RateModal/RateModal";
import OrderProduct from "@/views/Orders/partials/OrderProduct";
import CartTotalBar from "@/views/Cart/partials/CartTotalBar";
import ConfirmModal from "@/components/ConfirmModal";
import Loading from "@/components/Loading";
import TrackOrder from "@/views/Orders/partials/TrackOrder";
import DisputeModal from "@/views/Orders/partials/DisputeModal";

export default {
  name: "Order",
  components: {
    TrackOrder,
    Loading,
    ConfirmModal,
    CartTotalBar,
    OrderProduct,
    RateModal,
    CartGroupLayout,
    DisputeModal
  },
  props: {
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: {
        order_received: false
      }
    };
  },
  methods: {
    ...mapActions(["setErrors"]),
    async orderReceived() {
      this.loading.order_received = true;
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("orders_received", {
          order_id: this.order.id
        });
        window.axios
          .post(url, {
            received: 1
          })
          .then(
            response => {
              this.loading.order_received = false;
              resolve(response.data);
            },
            error => {
              this.loading.order_received = false;
              let errors = error.response.data.errors;
              this.setErrors([errors]);
              reject(errors);
            }
          );
      });
    },
    async rateShop(shop_id, rating, comment) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_rate", {
          shop_id: shop_id
        });
        window.axios
          .post(url, {
            rating: rating,
            description: comment
          })
          .then(
            response => {
              resolve(response.data);
            },
            error => {
              let errors = error.response.data.errors;
              this.setErrors([errors]);
              reject(errors);
            }
          );
      });
    },
    async rateDriver(driver_id, rating, comment) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("driver_rate", {
          driver_id: driver_id
        });
        window.axios
          .post(url, {
            rating: rating,
            description: comment
          })
          .then(
            response => {
              resolve(response.data);
            },
            error => {
              let errors = error.response.data.errors;
              this.setErrors([errors]);
              reject(errors);
            }
          );
      });
    },
    shop_rated({ rating }, shop_id) {
      this.rateShop(shop_id, rating.rating, rating.comment).then(() => {
        this.$emit("shop_rated", {
          shop_id: this.order.shop_id,
          rating: rating
        });
      });
    },
    driver_rated({ rating }, driver_id) {
      this.rateDriver(driver_id, rating.rating, rating.comment).then(() => {
        this.$emit("driver_rated", {
          driver_id: driver_id,
          rating: rating
        });
      });
    },
    order_received() {
      this.orderReceived().then(() => {
        this.$emit("status_updated", "accepted");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//Actions
.btn-my-rating {
  background-color: map-get($colors, "gray");
  color: map-get($colors, "black");
  opacity: 1;
}
</style>
