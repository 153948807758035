<template>
  <div class="rating-holder">
    <div class="rating-input Aligner">
      <div class='btn-rating Aligner' @click.prevent="setRating(iteration_rating)" v-for="iteration_rating in max_stars"
              :key="iteration_rating">
        <i class="fas fa-star" v-if="rating !== null && iteration_rating <= rating"></i> <!-- Filled -->
        <i class="far fa-star" v-else></i> <!-- Unfilled -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateInput",
  props: {
    max_stars: {
      type: Number,
      default: () => {
        return 5;
      }
    },
    modelValue: {
      type: Number
    },
  },
  data() {
    return {
      rating: null,
    };
  },
  methods: {
    setRating(iteration_rating) {
      if (this.rating !== iteration_rating) {
        this.rating = iteration_rating;
      } else {
        this.rating = null;
      }

      this.$emit('update:modelValue', this.rating);
    }
  },
  created() {
    this.rating = this.modelValue;
  }
}
</script>

<style lang="scss" scoped>
.rating-holder {
  .rating-input {
    width: 100%;

    .btn-rating {
      background: none;
      border: 0;
      width: 40px;
      height: 40px;
      font-size: 23px;
      color: map-get($colors, "twiks");

      &:hover {
        cursor:pointer;
      }
    }
  }
}
</style>
