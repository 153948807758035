<template>
  <span @click="showModal">
    <slot></slot>
  </span>

  <div
      ref='modal'
      class="modal fade track-order-modal"
      tabindex="-1"
  >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <slot name="header">
                <h5 class="modal-title font-weight-bold">
                  {{ $t("orders.tracking.modal.title") }}
                </h5>
              </slot>
            </div>
            <i
                class="fas fa-times-circle close"
                v-bind:aria-label="$t('global.cancel')"
                data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="modal-body">
            <loading :loading="loading"/>
            <div :id="'tracking_map_'+ random_id" class="ratio ratio-4_3"></div>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import Loading from "@/components/Loading";
import {ref} from "vue";
import {mapActions} from "vuex";
export default {
  name: "TrackOrder",
  setup() {
    const modal = ref(null);

    return {
      modal
    };
  },
  components: {Loading},
  props:{
    order_id: {
      type: Number
    }
  },
  data() {
    return {
      config: {
        interval: 10000
      },
      // API
      loading:true,
      latitude: null,
      longitude:null,
      driver:null,
      //Interval
      interval: null,
      //Modal
      bs_modal:null,
      random_id: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, ""),
      //Google MAPS
      map: null,
      marker: null
    }
  },
  computed : {
    geolocation(){
      return {
        lat: this.latitude,
        lng: this.longitude
      };
    }
  },
  methods:{
    ...mapActions(['setErrors']),
    //MODAL
    showModal() {
      if (this.bs_modal === null) {
        this.bs_modal = new window.bootstrap.Modal(this.modal, {});
      }
      this.bs_modal.show();

      // Listen for hidden event
      this.modal.addEventListener('hidden.bs.modal',  () => {
        this.onModalClosed();
      })

      // Get orders location
      // Orders location will be refresh every 10 seconds
      this.interval = setInterval(() => {
        //Get Location
        this.getOrderLocation().then(() => {
          //On success
          //Create map element if is null
          this.createMapElement(this.geolocation);
          //Reposition or create marker if is null
          this.repositionMarker(this.geolocation);
        }, (errors) => {
          //On error
          //Clear map,marker,etc.
          this.setErrors([errors]);
          this.bs_modal.hide();
        });
      },this.config.interval)
    },
    onModalClosed(){
      clearInterval(this.interval);
      this.map = null;
      this.marker = null;
      this.loading = true;
      document.getElementById("tracking_map_"+ this.random_id).innerHTML = "";
    },


    //API
    async getOrderLocation() {
      //Clean data
      return new Promise((resolve, reject) => {
        window.axios
            .get(
                window.Routes.getRoute("orders_track", {
                  order_id: this.order_id,
                })
            )
            .then(
                (response) => {
                  let data = response.data.data;
                  this.loading = false;
                  this.driver = data.driver;
                  this.latitude = data.latitude;
                  this.longitude = data.longitude;
                  resolve(response.data.data);
                },
                (error) => {
                  // Error setting is not needed here
                  reject(error.response.data.errors);
                }
            );
      });
    },

    // GOOGLE MAPS METHODS
    createMapElement(geolocation) {
      if(this.map === null){
        this.map = new window.google.maps.Map(document.getElementById("tracking_map_"+ this.random_id), {
          center: geolocation,
          zoom: 9,
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.BOTTOM_LEFT,
          },
        });
      }
    },
    createMarker(latlng = null) {
      if (this.marker === null) {
        this.marker = new window.google.maps.Marker({
          map: this.map,
          draggable: false,
          anchorPoint: new window.google.maps.Point(0, -29),
        });
        if (latlng != null) {
          this.marker.setPosition(latlng);
        }
      }
    },
    repositionMarker(latlng) {
      if (this.marker === null) {
        this.createMarker(latlng);
      } else {
        this.marker.setPosition(latlng);
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.modal.track-order-modal {
  #tracking_map {
    width:100%;
    background-color:red;
  }
}


@include media-breakpoint-up(sm) {
  .modal.track-order-modal {
    .modal-dialog {
      max-width: 800px;
      padding:15px;
    }
  }
}
</style>
