<template>
  <div class="rate-modal-wrapper">
    <span>
      <button
        @click="showModal"
        type="button"
        class="btn btn-outlined  btn-skinny font-size-sm w-100"
        v-bind:class="{ openDispute: !order.disputed?.id }"
      >
        {{ !order.disputed?.id ? $t("dispute.open_dispute") : "view dispute" }}
      </button>
    </span>

    <!-- Modal -->
    <div
      ref="modal"
      class="modal fade"
      :id="'rate_modal_' + unique_id"
      tabindex="-1"
      :aria-labelledby="'rate_modal_label_' + unique_id"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-notice">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title font-weight-bold"
              :id="'rate_modal_label_' + unique_id"
            >
              {{ $t("dispute.open_dispute") }}
            </h5>
            <i
              class="fas fa-times-circle close mt-1"
              v-bind:aria-label="$t('global.cancel')"
              data-bs-dismiss="modal"
            ></i>
          </div>
          <div class="modal-body">
            <textarea
              v-model="message"
              cols="30"
              rows="3"
              class="form-control font-size-md"
              :placeholder="$t('dispute.dispute_description')"
            ></textarea>

            <div class="uploadBox">
              <div
                v-if="thumbnailImage.length < 1"
                style="justify-content: center; display: flex;flex-direction: column;align-items: center"
              >
                <img src="@/assets/upload.svg" class="uploadIcon" />
                <div class="attachStyle font-size-md">
                  {{ $t("dispute.attach_file") }}
                </div>
                <div class="lineContainer">
                  <div class="dragLabel font-size-md">
                    {{ $t("dispute.drag_drop") }}
                  </div>
                  <div>
                    <label
                      class="browserLabel font-size-md"
                      :for="'uploadDisputeImage' + unique_id"
                    >
                      {{ $t("dispute.browse") }}
                    </label>
                    <input
                      type="file"
                      :id="'uploadDisputeImage' + unique_id"
                      ref="file"
                      v-on:change="handleFileChange()"
                      class="inputImage"
                    />
                  </div>
                </div>
              </div>
              <img
                :src="thumbnailImage"
                class="imageStyle"
                v-if="thumbnailImage.length > 0"
              />
            </div>
          </div>
          <div class="modal-footer centered-vertically">
            <button
              @click="openOrderDispute"
              class="btn btn-warning"
              :disabled="message.length < 1"
            >
              {{ $t("dispute.open_dispute") }}
            </button>
            <button type="button" class="btn btn-clean" data-bs-dismiss="modal">
              {{ $t("global.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { getBase64, thumbnailify } from "@/includes/base64";

export default {
  name: "DisputeModal",
  setup() {
    const form = ref(null);
    const modal = ref(null);

    return {
      form,
      modal
    };
  },
  props: {
    order: {
      type: Object
    }
  },
  data() {
    return {
      bs_modal: null,
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
      message: "",
      files: [],
      file: "",
      image: "",
      thumbnailImage: ""
    };
  },
  methods: {
    showModal() {
      if (this.order.disputed?.id) {
        this.$router.push({
          path: `/dispute/${this.order.id}`
        });
      } else {
        if (this.bs_modal === null) {
          this.bs_modal = new window.bootstrap.Modal(this.modal, {});
        }
        this.bs_modal.show();
      }
    },
    handleFileChange() {
      if (this.$refs.file.files[0] != undefined) {
        this.file = this.$refs.file.files[0];
        this.submitFile();
      }
    },
    submitFile() {
      this.thumbnailImage = "base64Thumbnail";

      let formData = new FormData();
      formData.append("file", this.file);

      window.axios
        .post(window.Routes.getRoute("upload_media"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          this.image = data.name;
          getBase64(this.file).then(base64 => {
            thumbnailify(base64, 50).then(base64Thumbnail => {
              this.thumbnailImage = base64Thumbnail;
            });
          });
        });
    },

    openOrderDispute() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("open_dispute", {
          id: this.order.id
        });
        window.axios
          .post(url, {
            body: this.message,
            images: this.image.length > 1 ? [this.image] : []
          })
          .then(
            response => {
              resolve(response.data.data);
              this.bs_modal.hide();
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
          );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.imageStyle {
  width: 100px;
  height: 100px;
}
.openDispute {
  border: 1px solid #dc5d52;
  border-radius: 5px;
}
.rate-modal-wrapper {
  .modal {
    .modal-body {
      .form-control {
        border-color: transparent;
        border-bottom: 1px solid map-get($colors, "gray");
        border-radius: 0;
        &:focus {
          outline: transparent;
          box-shadow: none;
        }
      }
      .uploadBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        height: 155px;
        border: 1px dashed map-get($colors, "gray");
        flex-direction: column;

        .uploadIcon {
          width: 40px;
          height: 40px;
        }
        .attachStyle {
          color: map-get($colors, "secondary");
          font-weight: 400;
          margin-top: 10px;
        }
        .lineContainer {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          .inputImage {
            display: none;
          }
          .browserLabel {
            margin-left: 4px;
            color: map-get($colors, "black");
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .presentation {
        padding-bottom: map-get($spacings, "small");
        border-bottom: 1px solid map-get($colors, "gray");

        .image {
          border: 1px solid map-get($colors, "gray");
          border-radius: map-get($defaults, "border-radius");
          width: 40px;
          background-size: cover;
        }

        .description {
          padding-left: map-get($spacings, "small");
        }
      }
    }
  }

  .btn-my-rating {
    background-color: map-get($colors, "gray");
    color: map-get($colors, "black");
    opacity: 1;
  }
}
</style>
