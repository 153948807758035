<template>
  <div>
    <div class="cart-product row">
      <div class="col-auto image_holder d-none d-md-block">
        <div class="image ratio ratio-4_3">
          <div class="body" :style="
          'background-image: url(' +
          window.Routes.getImage(ordered_product.product.image, 'preview') +
          ');'
        "></div>
        </div>
      </div>
      <div class="col body">
        <!-- Title -->
        <div class="row justify-content-between">
          <div class="col-lg Aligner items-left title">
            <div class="d-block d-md-none image small-image ratio ratio-4_3">
              <div class="body"
                   :style="'background-image: url(' +window.Routes.getImage(ordered_product.product.image, 'preview') + ');'"></div>
            </div>

            <span class="font-weight-bold font-size-md">{{ ordered_product.product.name }}</span>
          </div>
          <div class="col-lg-auto">
            <div class="row quantity_row">
              <div class="col-auto Aligner">
                <span class="font-weight-bold">
                  {{
                    formatPrice(ordered_product.product.currency, ordered_product.price + ordered_product.total_variant_price)
                  }}
                  x {{ ordered_product.quantity }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Details -->
        <div class="row">
          <!-- Preparing time -->
          <div class="col-12">
            <span class="color-secondary">{{ $t("products.preparing_time") }} </span> {{
              ordered_product.product.delay
            }}
            {{ $t("global.timedata.min") }}
          </div>
          <!-- Variant -->
          <div class="col-12" v-if="ordered_product.ordered_variants.length !== 0">
            <span class="color-secondary">{{ ordered_product.ordered_variants[0]['variant_type'].name}}</span>
            : {{ ordered_product.ordered_variants[0].name }}
          </div>
        </div>

        <cart-product-extras :editable="false"
                             :selected_only="true"
                             v-model='formated_extras'
                             :product_extras="ordered_product.ordered_extras"/>

        <!--    Rate Product    -->
        <div class="rate_product mt-2">
          <rate-modal
              :allow_rating="order_status === 'accepted'"
              :presentation_image="window.Routes.getImage(ordered_product.product.image, 'preview')"
              :presentation_text="ordered_product.product.name"
              :rating="ordered_product.product.rating"
              :my_rating="my_rating"
              @ratingSaved="product_rated"
          >
            <template v-slot:default>
              <button type="button" class="btn btn-primary btn-skinny font-size-sm">
                <i class="far fa-star me-2"></i> {{ $t("ratings.rate_product") }}
              </button>
            </template>

            <template v-slot:rating-exists="{my_rating}">
              <button disabled class="btn btn-disabled btn-my-rating font-size-sm">
                {{ $t("ratings.your_rate") }}: <span class="ms-2"><i class="fas fa-star"></i> {{
                  my_rating.rating
                }}</span>
              </button>
            </template>
          </rate-modal>
        </div>

        <!--   SubTotal   -->
        <div class="row subtotal">
          <div class="col-12 text-end">
            <span class="text-uppercase color-secondary pe-2">{{ $t("global.price.subtotal") }} </span>
            <span class="font-weight-bold font-size-md">
            {{ formatPrice(ordered_product.product.currency, ordered_product.total_amount) }}
          </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import formatPrice from "@/includes/currency";
import CartProductExtras from "@/views/Cart/partials/CartProductExtras";
import RateModal from "@/components/RateModal/RateModal";

export default {
  name: "OrderProduct",
  components: {RateModal, CartProductExtras},
  props: {
    order_status: {
      type: String
    },
    ordered_product: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      my_rating : null,
      formated_extras: {}
    }
  },
  methods: {
    ...mapActions(["setErrors"]),
    async rateProduct(rating, comment) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("product_rate", {
          product_id: this.ordered_product.product.id,
        });
        window.axios
            .post(url, {
              "rating": rating,
              "description": comment,
            })
            .then(
                (response) => {
                  resolve(response.data);
                },
                (error) => {
                  reject(error.response.data.errors);
                }
            );
      });
    },
    mapExtras(extras) {
      let formated_extras = {};
      for (const extraKey in extras) {
        formated_extras[extras[extraKey]['id']] = extras[extraKey].quantity;
      }
      return formated_extras;
    },
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
    product_rated({rating}) {
      this.rateProduct(rating.rating, rating.comment).then(() => {
        this.my_rating = {
          rating: rating.rating,
          description: rating.description,
        };
      }, (errors) => {
        this.setErrors([errors]);
      });
    }
  },
  created() {
    this.formated_extras = this.mapExtras(this.ordered_product.ordered_extras);
    this.my_rating = this.ordered_product.product.my_rating;
  }
}
</script>

<style lang="scss" scoped>
.cart-product {
  .image {
    width: 100px;
    border: 1px solid map-get($defaults, "border-color");
    border-radius: map-get($defaults, "border-radius");
    overflow: hidden;

    .body {
      background-size: cover;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }


  & > .body {
    .title {
      margin-bottom: map-get($spacings, "xsmall");
    }

    .btn-my-rating {
      background-color: map-get($colors, "gray");
      color: map-get($colors, "black");
      opacity: 1;
    }

    .quantity_row {
      .quantity-holder {
        width: 100px;
      }
    }
  }

}

@include media-breakpoint-down(lg) {
  .cart-product {
    .small-image {
      width: 40px;
      margin-right: map-get($spacings, "xsmall");
    }

    .quantity_row {
      .quantity-holder {
        margin-top: map-get($spacings, "xsmall");
        margin-bottom: map-get($spacings, "small");
        width: 100px;
      }
    }

  }
}
</style>
