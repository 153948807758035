<template>
  <div class="rate-modal-wrapper">
    <slot v-if="my_rating !== null" name="rating-exists" v-bind:my_rating="my_rating">
      <button disabled class="btn btn-disabled btn-my-rating font-size-sm">
        {{ $t("ratings.your_rate") }}: <span class="ms-2"><i class="fas fa-star"></i> {{ my_rating.rating }}</span>
      </button>
    </slot>

    <span v-if="my_rating === null && allow_rating === true" @click="showModal">
      <slot>
        <button type="button" class="btn btn-primary font-size-sm">
          <i class="far fa-star me-2"></i> {{ toggle_btn_text != null ? toggle_btn_text : $t("ratings.rate_btn") }}
        </button>
      </slot>
  </span>

    <!-- Modal -->
    <div ref='modal' v-if="my_rating === null" class="modal fade" :id="'rate_modal_'+unique_id" tabindex="-1"
         :aria-labelledby="'rate_modal_label_'+unique_id" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-notice">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold" :id="'rate_modal_label_' + unique_id">
              {{ $t("ratings.rate_product") }}
            </h5>
            <i
                class="fas fa-times-circle close mt-1"
                v-bind:aria-label="$t('global.cancel')"
                data-bs-dismiss="modal"
            ></i>
          </div>
          <form ref="form" @submit.prevent="formSubmitted">
            <div class="modal-body">
              <div class="presentation row g-0" v-if="presentation_image !== null || presentation_text !== null">
                <div class="col-auto" v-if="presentation_image !== null">
                  <div class="image ratio" :style='"background-image:url("+presentation_image+")"'></div>
                </div>
                <div class="description col Aligner items-left font-weight-bold" v-if="presentation_text !== null">
                  {{ presentation_text }}
                </div>
              </div>

              <div class="mb-3 py-4">
                <rate-input class="mb-1" v-model="form_data.rating" :max_stars="max_stars"/>

                <div class="your-rating color-twiks Aligner" v-if="form_data.rating !== null">
                  {{ $t("ratings.rated_out_of_max", {your_rate: form_data.rating, max_stars: max_stars}) }}
                </div>
                <div class="total-ratings Aligner">
                  <template v-if="rating !== null">
                    {{ $t("ratings.current_rating") }}: {{ rating }}
                  </template>
                  <template v-else>
                    {{ $t("ratings.no_ratings_yet") }}
                  </template>
                </div>

              </div>

              <div class="mb-3">
              <textarea
                  name="comment"
                  class="form-control"
                  cols="30"
                  rows="5"
                  :placeholder="$t('global.leave_comment')"
                  v-model="form_data.comment"
              ></textarea>
              </div>

            </div>
            <div class="modal-footer centered-vertically">
              <button type="submit" class="btn btn-primary" :disabled="form_data.rating === null"
                      :class="{'disabled': form_data.rating === null}">
                {{ save_btn_text != null ? save_btn_text : $t("global.save") }}
              </button>
              <button type="button" class="btn btn-clean" data-bs-dismiss="modal">
                {{ $t('global.cancel') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import RateInput from "@/components/RateModal/RateInput";

export default {
  name: "RateModal",
  setup() {
    const form = ref(null);
    const modal = ref(null);

    return {
      form,
      modal
    };
  },
  components: {RateInput},
  props: {
    allow_rating: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    presentation_image: {
      type: [Boolean, String],
      default: () => {
        return null;
      }
    },
    presentation_text: {
      type: [Boolean, String],
      default: () => {
        return null;
      }
    },
    max_stars: {
      type: Number,
      default: () => {
        return 5
      }
    },
    toggle_btn_text: {
      type: [Boolean, String],
      default: () => {
        return null;
      }
    },
    save_btn_text: {
      type: [Boolean, String],
      default: () => {
        return null;
      }
    },
    my_rating: {
      type: [Object],
      default: () => {
        return null;
      }
    },
    rating: {
      type: [Number],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      bs_modal: null,
      unique_id: Math.floor(Math.random() * (9999 - 1000)) + 1000,
      form_data: {
        rating: this.my_rating !== null ? this.my_rating.rating : null,
        comment: "",
      }
    }
  },
  methods: {
    showModal() {
      if (this.bs_modal === null) {
        this.bs_modal = new window.bootstrap.Modal(this.modal, {});
      }
      this.bs_modal.show();
    },
    formSubmitted() {
      // Emit on save
      this.$emit("ratingSaved", {
        rating: this.form_data
      });
      // Close modal
      this.bs_modal.hide();
    }
  },
}
</script>

<style lang="scss" scoped>
.rate-modal-wrapper {
  .modal {
    .modal-body {
      .presentation {
        padding-bottom: map-get($spacings, "small");
        border-bottom: 1px solid map-get($colors, "gray");

        .image {
          border: 1px solid map-get($colors, "gray");
          border-radius: map-get($defaults, "border-radius");
          width: 40px;
          background-size: cover;
        }

        .description {
          padding-left: map-get($spacings, "small");
        }
      }
    }
  }

  .btn-my-rating {
    background-color: map-get($colors, "gray");
    color: map-get($colors, "black");
    opacity: 1;
  }
}


</style>
